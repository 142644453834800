<template lang="html">


  <div class="usluga">
    <div class="headUsluga">


  <router-link class="navbar-brand" to="/ksuslugeEng">Back </router-link>
    <h3>Talent Management</h3>
    <p>Talents are those people who contribute to the development of the Company and the achievement of the Company's goals, thus create additional value for the Company and have a strong impact on the business of the organization. Talent management is a process that takes place through several phases, which include attracting the most talented employees, recognizing talents in the Company, defining ways to develop Talent potential, aligning Talent potential with Company goals, forming a corporate culture that encourages employee performance and talent motivation.</p>
    <h5>You need these services? Fill the form below:</h5>

        </div>
    <div class="card-body">


         <div class="form-group">
           <label for="numberOfEmployees">Number of employees that participate in the Talent program(Assessment of internal teams)</label>
           <input
             class="form-control"
             type="text"
             placeholder=""
             name="numberOfEmployees"
             v-model="numberOfEmployees"
             id="numberOfEmployees"
             >
         </div>
        <div class="form-group">
          <label for="companyName">Company name<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="companyName"
            v-model="companyName"
            id="companyName"
            >
        </div>
        <div class="form-group">
          <label for="contactName">Full name of contact person<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="contactName"
            v-model="contactName"
            id="contactName"
            >
        </div>
        <div class="form-group">
          <label for="contactNumber">Phone number<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="contactNumber"
            v-model="contactNumber"
            id="contactNumber"
            >
        </div>
        <div class="form-group">
          <label for="email">E-mail<p style="display:inline;color:red">*</p></label>
          <input
            class="form-control"
            type="text"
            placeholder=""
            name="email"
            v-model="email"
            id="email"
            >
        </div>



        <div class="validation">
          {{validation}}
        </div>
        <button class="btn btn-primary" @Click="submit">Submit</button>
        &nbsp;&nbsp;&nbsp;&nbsp;



  </div>

  </div>


</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
export default {

  name: 'TalentMenagementEng',
  components: {

  },
  data(){
    return{
      companyName:"",
  contactName:"",
  contactNumber:"",
  email:"",
  numberOfEmployees :"",
  validation:"",
  date:"",
    }
  },
  methods:{
  ...mapActions(['submitServiceAplication']),
  submit(){

    this.validation="";

    if(this.companyName==""){
      this.validation="Unesite naziv kompanije."
    }
    if(this.contactName==""){
      this.validation="Unesite ime i prezime kontakt osobe."
    }
    if(this.email==""){
      this.validation="Unesite email."
    }
    if(this.contactNumber==""){
      this.validation="Unesite kontakt telefon."
    }
    const d = new Date();
    let day = d.getDate();
    let month = d.getMonth()+1;
    let year = d.getFullYear();
    this.date= "Datum: "+day+"."+month+"."+year+".";

    let text = [];
    text.push("Talent Management");
    text.push(this.date);
    text.push("Naziv Kompanije: "+this.companyName);
    text.push("Ime i Prezime Kontakt Osobe: "+this.contactName);
    text.push("Email: "+this.email);
    text.push("Kontakt telefon: "+this.contactNumber  );
    text.push("Broj zaposlenih za učešće u Talent programu (Procena internih timova): "+this.numberOfEmployees);

    let data ={
      formular:text
    }

    if(this.validation==""){
    this.submitServiceAplication(data);
    this.validation="Uspesno poslat formular."
    }
  },
},
  beforeCreate: function() {
        document.body.className = 'usluge';
  }
}
</script>

<style lang="css" scoped>
.usluga{
  position: absolute;
  top: auto;
  left: 10%;
  right: 10%;
  background-color: white;
  border-style: solid;
  border-color: black;
  border-width: medium;
  border-radius: 15px;
  margin-top: 15px;

}
.headUsluga{
  background-color: #77bbee;
  padding: 10px;
  border-top-left-radius:  10px;
  border-top-right-radius:  10px;
}
.card-body{
  background-color: #bbffff;
  border-bottom-left-radius:  10px;
  border-bottom-right-radius:  10px;
}
.validation{
  margin-top: 5px;
  background-color: #00aaaa;
}
</style>
